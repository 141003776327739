<template>
  <div class="buyHome">
    <div class="cell" v-if="type == 'main'">
      <div>我的档案</div>
      <div class="miaosu">管理您的个人资料以管制和确保您的账号安全</div>
      <div style="display: flex">
        <div style="width: 650px; padding: 0 60px">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="用户账号">
              <el-input
              readonly
                v-model="form.acc"
                style="max-width: 200px"
                :disabled="is_modify == 1"
              ></el-input>
              <!-- <span style="font-size: 12px; color: #b0b0b0; margin-left: 20px"
                >用户账号仅限修改一次</span
              > -->
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="电邮" v-if="types == 1">
              {{ email }}
              <span
                @click="handleEdit(1)"
                style="
                  color: #0055aa;
                  text-decoration: underline;
                  margin-left: 4px;
                  cursor: pointer;
                "
                >更改</span
              >
            </el-form-item>
            <el-form-item label="手机号码" v-if="types == 1">
              {{ phone }}
              <span
                @click="handleEdit(2)"
                style="
                  color: #0055aa;
                  text-decoration: underline;
                  margin-left: 4px;
                  cursor: pointer;
                "
                >更改</span
              >
            </el-form-item>
            <el-form-item label="显示名称">
              <el-input v-model="form.Sname"></el-input>
            </el-form-item>

            <el-form-item label="性别">
              <el-radio-group v-model="form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
                <el-radio :label="0">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生日">
              <DateSelect
                :birtydayInfo="birtydayInfo"
                @setBirthDay="setBirthDay"
              ></DateSelect>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">储存</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="line"></div>
        <div class="txsc">
          <el-avatar
            :size="80"
            :src="
              $store.state.buyUserInfo.avatar
                ? $store.state.buyUserInfo.avatar
                : tx
            "
          ></el-avatar>
          <div style="margin: 20px 0">
            <el-button plain @click="dialogVisible = true">选择图片</el-button>
          </div>
          <div style="font-size: 14px; color: #999">
            文件大小：最大1MB
            <br />
            文件扩展：JPEG,PNG
          </div>
        </div>
      </div>
    </div>
    <div class="cell" v-if="type == 'eml'">
      <div>修改电邮</div>
      <div class="miaosu">请输入新电邮地址，并输入账户密码进行确认。</div>
      <div style="display: flex; justify-content: center; padding: 40px 0">
        <el-form ref="form1" :model="form1" label-width="120px">
          <el-form-item label="新邮件地址">
            <el-input style="min-width: 300px" v-model="form1.eml"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input v-model="form1.password" show-password></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="handleEdit(3)"
              :disabled="form1.eml == '' || form1.password == ''"
              type="primary"
              >确认</el-button
            >
            <el-button @click="handleCancel" plain>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="cell" v-if="type == 'phone'">
      <div>修改电话号码</div>
      <div class="miaosu">为了您的安全，请验证密码以确认您的身份。</div>
      <div
        style="display: flex; justify-content: center; padding: 40px 0"
        v-if="seep == 1"
      >
        <el-form ref="form2" :model="form1" label-width="120px">
          <el-form-item label="手机号码">
            <div>{{ phone }}</div>
          </el-form-item>
          <el-form-item label="Shopee密码">
            <el-input
              v-model="form2.password"
              show-password
              placeholder="密码"
              style="min-width: 320px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="handleEdit(4)"
              :disabled="form2.password == ''"
              type="primary"
              >确认</el-button
            >
            <el-button @click="handleCancel" plain>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        style="display: flex; justify-content: center; padding: 40px 0"
        v-if="seep == 2"
      >
        <el-form ref="form2" :model="form1" label-width="120px">
          <el-form-item label="新手机号码">
            <el-input
              type="tel"
              v-model="form2.nPhone"
              style="min-width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input
              v-model="form2.qrPwd"
              show-password
              style="min-width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="handleEdit(5)"
              :disabled="form2.nPhone == '' || form2.qrPwd == ''"
              type="primary"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <avatar-cropper
      :dialogVisible.sync="dialogVisible"
      @closeAvatarDialog="closeAvatarDialog"
    ></avatar-cropper>
  </div>
</template>
<script>
import {
  updateUserInfo,
  editUserAvatar,
  editUserEmail,
  editUserMobile,
} from "@/api/buy.js";
import DateSelect from "@/components/DateSelect";
import avatarCropper from "@/components/avatarCropper";
import { uploadFiles } from "@/api/common.js";
import env from "@/settings/env.js";
import { getBAuth } from "@/utils/auth.js";
import { buyUserInfo } from "@/api/buy.js";
import tx from "@/assets/img/home/headImg.png";

export default {
  components: {
    DateSelect,
    avatarCropper,
  },
  data() {
    return {
      tx: tx,
      fileUrl: env.fileUrlPrefix,
      dialogVisible: false,
      phone: "",
      email: "",
      is_modify: 0,
      form: {
        acc: "",
        name: "",
        Sname: "",
        gender: "",
      },
      form1: {
        eml: "",
        password: "",
      },
      form2: {
        password: "",
        phone: "",
        nPhone: "",
        qrPwd: "",
      },
      birtydayInfo: { year: "", month: "", day: "" },
      memberInfo: {
        birthday: "",
      },
      circleUrl: "",
      circleUrls: "",
      circleUrlID: "",
      type: "main",
      seep: 1,
      types: 1,
    };
  },
  created() {
    this.types = localStorage.BUTLOGINTYPE;
    this.$store.state.buyMenuIndex = 11;
    if (this.$store.state.buyUserInfo.birthday) {
      let arr = this.$store.state.buyUserInfo.birthday.split("/");
      this.birtydayInfo.year = arr[0];
      this.birtydayInfo.month = arr[1];
      this.birtydayInfo.day = arr[2];
    }
    if (this.$store.state.buyUserInfo.avatar) {
      this.circleUrls = this.$store.state.buyUserInfo.avatar;
    }
  },
  mounted() {
    this.getData();

    if (!getBAuth()) {
      this.$router.replace("buyLogin");
    }
  },

  methods: {
    getData() {
      buyUserInfo().then((res) => {
        if (res.code == 1) {
          this.$store.state.buyUserInfo = res.data;
          this.phone = res.data.mobile;
          this.email = res.data.email;
          this.form = {
            acc: res.data.account,
            name: res.data.realname,
            Sname: res.data.username,
            gender: res.data.gender,
          };
          this.is_modify = res.data.is_modify;
          if (res.data.birthday) {
            let arr = res.data.birthday.split("/");
            this.birtydayInfo.year = arr[0];
            this.birtydayInfo.month = arr[1];
            this.birtydayInfo.day = arr[2];
          }
        }
      });
    },
    setBirthDay(birtydayInfo) {
      this.birtydayInfo = birtydayInfo;
      let { year = "1900", month = "1", day = "1" } = this.birtydayInfo;
      this.memberInfo.birthday = `${year}-${month}-${day}`;
    },
    onSubmit() {
      updateUserInfo({
        account: this.form.acc,
        realname: this.form.name,
        username: this.form.Sname,
        gender: this.form.gender,
        birthday:
          this.birtydayInfo.year +
          "/" +
          this.birtydayInfo.month +
          "/" +
          this.birtydayInfo.day,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.$store.state.buyUserInfo.realname = this.form.name;
          this.$store.state.buyUserInfo.username = this.form.Sname;
          this.$store.state.buyUserInfo.gender = this.form.gender;
          if (this.$store.state.buyUserInfo.account == "") {
            this.$store.state.buyUserInfo.account = this.form.acc;
          }

          this.$store.state.buyUserInfo.birthday =
            this.birtydayInfo.year +
            "/" +
            this.birtydayInfo.month +
            "/" +
            this.birtydayInfo.day;
        }
      });
    },
    closeAvatarDialog(data) {
      let formData = new FormData();
      formData.append("imagelist", data.data, data.fileName);
      uploadFiles(formData).then((res) => {
        if (res.code == 1) {
          this.circleUrl = res.data.short_url;
          this.circleUrls = res.data.url;
          this.circleUrlID = res.data.id;
          editUserAvatar({
            avatar: this.circleUrlID,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.message);
              this.$store.state.buyUserInfo.avatar = this.circleUrls;
            }
          });
          this.$forceUpdate();
        }
      });
      this.dialogVisible = false;
    },
    handleEdit(type) {
      if (type == 1) {
        this.form1 = {
          eml: "",
          password: "",
        };
        this.type = "eml";
      }
      if (type == 2) {
        this.type = "phone";
        this.form2.password = "";
      }
      if (type == 3) {
        editUserEmail({
          email: this.form1.eml,
          password: this.form1.password,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            this.type = "main";
            this.email = this.form1.eml;
          }
        });
      }
      if (type == 4 || type == 5) {
        editUserMobile({
          mobile: this.phone,
          password: this.form2.password,
          new_mobile: this.form2.nPhone,
          confirm_password: this.form2.qrPwd,
          type: type == 4 ? 1 : 2,
        }).then((res) => {
          if (res.code == 1) {
            if (type == 4) {
              this.seep = 2;
            }
            if (type == 5) {
              this.$message.success(res.message);
              this.type = "main";
              this.phone = this.form2.nPhone;
            }
          }
        });
      }
      // if (type == 5) {

      // }
    },
    handleCancel() {
      this.type = "main";
    },
  },
};
</script>
<style lang="less" scoped>
.buyHome {
  padding: 20px;
  .cell {
    background: #fff;
    padding: 30px;
    color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    .miaosu {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin: 6px 0 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #bebebe;
    }
    .line {
      height: 228px;
      width: 1px;
      background: rgba(0, 0, 0, 0.1);
    }
    .txsc {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>