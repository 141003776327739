<template>
  <div>
    <!-- {{}} -->
    <el-row>
      <el-select :value="curYear" @change="setYear" style="max-width: 90px">
        <el-option
          v-for="item in yearList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <span style="margin-left: 10px">年</span>
      <el-select
        :value="curMonth"
        @change="setMonth"
        style="max-width: 90px; margin-left: 30px"
      >
        <el-option
          v-for="item in monthList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <span style="margin-left: 10px">月</span>
      <el-select
        :value="curDay"
        @change="setDay"
        style="max-width: 90px; margin-left: 30px"
      >
        <el-option
          v-for="item in dayList"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <span style="margin-left: 10px">日</span>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["birtydayInfo"],
  name: "DateSelect",
  computed: {
    curYear() {
      return this.birtydayInfo.year;
    },
    curMonth() {
      return this.birtydayInfo.month;
    },
    curDay() {
      return this.birtydayInfo.day;
    },
    yearList() {
      let currentYear = new Date().getFullYear();
      return this.getYearArr(1900, currentYear);
    },
    dayList() {
      let monthRange = this.getMonthDay(this.curYear, this.curMonth);
      return this.getNumberArr(1, monthRange);
    },
  },
  created(){
  },
  data() {
    return {
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  methods: {
    getNumberArr(a, b) {
      return Array.from(Array(b - a + 1)).map((e, i) => a + i);
    },
    getYearArr(a, b) {
      return Array.from(Array(b - a + 1))
        .map((e, i) => a + i)
        .reverse();
    },
    getMonthDay(year, month) {
      let days = new Date(year, month, 0).getDate();
      return days;
    },
    setYear(year) {
      this.birtydayInfo.year = year;
      this.$emit("setBirthDay", this.birtydayInfo);
    },
    setMonth(month) {
      this.birtydayInfo.month = month;
      this.$emit("setBirthDay", this.birtydayInfo);
    },
    setDay(day) {
      this.birtydayInfo.day = day;
      this.$emit("setBirthDay", this.birtydayInfo);
    },
  },
};
</script>